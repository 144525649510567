import { render, staticRenderFns } from "./Muziek.vue?vue&type=template&id=318e80b0&scoped=true&"
import script from "./Muziek.vue?vue&type=script&lang=js&"
export * from "./Muziek.vue?vue&type=script&lang=js&"
import style0 from "./Muziek.vue?vue&type=style&index=0&id=318e80b0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318e80b0",
  null
  
)

export default component.exports