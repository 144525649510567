import axios from 'axios'

const baseUrl = '/php/db-sqlite.php/';
// const baseUrl = 'http://localhost:2222/static/db-sqlite.php/';

export default {
    // add row to database
    addRow: function (table, newRow) {
        return new Promise(function (resolve, reject) {
            var url = baseUrl + '/' + table;
            axios.post(url, newRow)
                .then(response => {
                    // console.log(response);
                    resolve(response);
                })
                .catch(e => {
                    // console.log(e);
                    reject(e)
                });
        })
    },

    // get all records from database
    getData: function (table) {
        return new Promise(function (resolve, reject) {
            // console.log('get', baseUrl, table);
            axios.get(baseUrl + table)
                .then(response => {
                    // console.log(response.data);
                    resolve(response.data);
                })
                .catch(e => {
                    // console.log(e);
                    reject(e);
                });
        })
    },

    // delete row from database
    deleteRow: function (table, id) {
        return new Promise(function (resolve, reject) {
            var HTTP = axios.create({
                baseURL: baseUrl,
                headers: {
                    "X-HTTP-Method-Override": "DELETE"
                }
            });

            HTTP.get(table + '/' + id)
                .then(response => {
                    console.log('deleted');
                    console.log(response);
                    resolve(response);
                })
                .catch(e => {
                    reject(e);
                });
        })
    },

    // update row in database
    updateRow: function (table, row) {
        return new Promise(function (resolve, reject) {
            var config = {
                headers: {
                    "X-HTTP-Method-Override": "PUT"
                }
            }

            var url = baseUrl + table + '/' + row.id;
            axios.post(url, row, config)
                .then(function (response) {
                    console.log(response);
                    resolve(response);
                })
                .catch(function (error) {
                    console.log(error);
                    resolve(error);
                });
        })
    }
}
