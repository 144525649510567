<template>
	<!------------------------------------------------------------------------------------>
	<!-- hero with buttons -->
	<!------------------------------------------------------------------------------------>
	<div class="container">
		<div class="row">
			<div class="col-md-10 offset-md-1">
				<div class="row font-weight-bold">
					<div class="col btn-red fill">
						<router-link to="/woorden">
							<p class="is-size-5 text-white my-3">woorden</p>
						</router-link>
					</div>
					<div class="col btn-blue fill">
						<router-link to="/praatplaten">
							<p class="is-size-5 text-white my-3">praatplaten</p>
						</router-link>
					</div>
					<div class="col btn-green fill">
						<router-link to="/muziek">
							<p class="is-size-5 text-white my-3">muziek</p>
						</router-link>
					</div>
					<div class="col btn-orange fill">
						<router-link to="/werkvormen">
							<p class="is-size-5 text-white my-3">werkvormen</p>
						</router-link>
					</div>
					<div class="col btn-purple fill">
						<router-link to="/meermateriaal">
							<p class="is-size-5 text-white my-3">meer materiaal</p>
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({}),
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
