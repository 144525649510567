<template>
    <div class="container">
        <video width="100%" height="auto" controls autoplay>
            <source src="/static/video/Gymmen met pim met ondertiteling 2023.mov" type="video/mp4">
            Your browser does not support the video tag.
        </video>
        <button class="btn btn-outline-info" @click="close">Sluiten</button>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },


    created: function () {
    },

    methods: {
        close() {
            this.$emit('close');
        }
    }
};
</script>

<style scoped>
.container {
    padding: 20px;
}
</style>
