<template>
<div>
    <div class="container mt-5">
        <div class="row justify-content-md-center">
            <div class="col-md-5">
                <p class="is-size-3">
                    haakpatroon
                </p>
                <a href="/static/haakpatroon/patroon.pdf">
                    <img class="img-fluid shadow" src="/static/haakpatroon/patroon.jpg" />
                </a>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import top from './Top.vue'
import buttons from './Buttons.vue'

export default {
    components: {
        top,
        buttons
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
