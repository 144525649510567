<template>
	<div>
		<div class="container mt-5">
			<!-- dagritmekaarten -->
			<div class="row mt-5">
				<div class="col">
					<p class="is-size-3">dagritmekaarten</p>
					<p class="font-weight-bold">Gebruiksaanwijzing</p>
					<p>
						De dagritmekaarten zijn gemaakt voor zowel peuters als kleuters. Kies de kaarten die het beste
						bij jullie dagritme past en druk ze af.
					</p>
				</div>
			</div>
			<div class="row align-items-center">
				<div class="col-6 col-sm-3 col-md-3 my-3" v-for="name of names" :key="name">
					<a :href="PATH + name + '.pdf'">
						<img class="img-fluid shadow" :src="PATH + name + '.png'" />
					</a>
					<p>{{ name }}</p>
				</div>
				<br />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {
		top,
	},

	data: () => ({
		PATH: '/static/dagritmekaarten/',
		names: [
			'cracker eten',
			'feestje vieren',
			'fruit eten',
			'in de kring',
			'naar de wc gaan',
			'naar huis',
			'spelen',
			'uitzwaaien',
			'buitenspelen',
			'eten en drinken',
			'tussendoortje',
			'filmpje kijken',
			'gymmen',
			'inloop',
			'opruimen',
			'optreden',
			'uitstapje',
			'verjaardag vieren',
			'werken',
			'zingen',
			'bewegen',
			'boekje lezen',
			'muziek',
		],
	}),

	created() {
		// console.clear();
	},

	methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
