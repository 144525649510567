import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/components/Main'
import About from '@/components/About'
import Woorden from '@/components/Woorden'
import Werkvormen from '@/components/Werkvormen'
import Meermateriaal from '@/components/Meermateriaal'
import Handleiding from '@/components/Handleiding'
import Haakpatroon from '@/components/Haakpatroon'
import Praatplaten from '@/components/Praatplaten'
import Kleurplaten from '@/components/Kleurplaten'
import Placemat from '@/components/Placemat'
import Muziek from '@/components/Muziek'
import Print from '@/components/Print'
import Pdf from '@/components/Pdf'
import Prentenboeken from '@/components/Prentenboeken'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: '/',
    routes: [{
        path: '/',
        name: 'Main',
        component: Main
    }, {
        path: '/over',
        name: 'About',
        component: About
    }, {
        path: '/woorden',
        name: 'Woorden',
        component: Woorden
    }, {
        path: '/praatplaten',
        name: 'Praatplaten',
        component: Praatplaten
    }, {
        path: '/werkvormen',
        name: 'Werkvormen',
        component: Werkvormen
    }, {
        path: '/meermateriaal',
        name: 'Meermateriaal',
        component: Meermateriaal
    }, {
        path: '/handleiding',
        name: 'Handleiding',
        component: Handleiding
    }, {
        path: '/haakpatroon',
        name: 'Haakpatroon',
        component: Haakpatroon
    }, {
        path: '/placemat',
        name: 'Placemat',
        component: Placemat
    }, {
        path: '/kleurplaten',
        name: 'Kleurplaten',
        component: Kleurplaten
    }, {
        path: '/muziek',
        name: 'Muziek',
        component: Muziek
    }, {
        path: '/print',
        name: 'Print',
        component: Print,
        props: true
    }, {
        path: '/prentenboeken',
        name: 'Prentenboeken',
        component: Prentenboeken,
        props: true
    }, {
        path: '/pdf',
        name: 'Pdf',
        component: Pdf,
        props: true
    }]
})
