<template>
    <div>
        <buttons></buttons>

        <div class="container">
            <!-- <h1>Videos</h1>
<div class="row">
<div class="col-md-3 col-sm-6 col-12 mt-3">
<img class="img-fluid" :src="movie[0].poster" alt="movie1" @click="playMovie(0)" />
</div>
<div class="col-md-3 col-sm-6 col-12 mt-3">
<img class="img-fluid" :src="movie[1].poster" alt="movie1" @click="playMovie(1)" />
</div>
<div class="col-md-3 col-sm-6 col-12 mt-3">
<img class="img-fluid" :src="movie[2].poster" alt="movie1" @click="playMovie(2)" />
</div>
<div class="col-md-3 col-sm-6 col-12 mt-3">
<img class="img-fluid" :src="movie[3].poster" alt="movie1" @click="playMovie(3)" />
</div>
</div>
<div class="my-5"></div>-->

            <div class="row mt-5">
                <div class="col">
                    <p class="is-size-3">liedjes</p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8 offset-md-2 text-left">
                    <p class="font-weight-bold">Gebruiksaanwijzing</p>
                    <p>
                        Er zijn 19 liedjes geschreven en ingezongen door Tessa Kortenbach passend bij de verschillende
                        thema’s in Praten met Pim. De liedteksten zijn in een aparte download beschikbaar.
                    </p>
                    <p>
                        Selecteer het liedje, dat je wilt beluisteren. Er verschijnt een balk in beeld met het
                        audio-bestand. Het gekozen liedje begint direct met afspelen. In de balk zijn verschillende
                        functies beschikbaar, zoals pauzeren, 10 seconden terug en vooruit spoelen en de snelheid waarop
                        gezongen wordt aanpassen. Veel plezier met de liedjes!
                    </p>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-3 col-6">
                    <p @click="playAudio">Dit ben ik</p>
                    <p @click="playAudio">Gezellig in de groep</p>
                    <p @click="playAudio">Spelen in de groep</p>
                    <p @click="playAudio">Hiep hiep hoera</p>
                    <p @click="playAudio">Seizoenen</p>
                </div>
                <div class="col-md-3 col-6">
                    <p @click="playAudio">Zomer</p>
                    <p @click="playAudio">Herfst</p>
                    <p @click="playAudio">Mijn opa</p>
                    <p @click="playAudio">Winter</p>
                    <p @click="playAudio">Lente</p>
                </div>
                <div class="col-md-3 col-6">
                    <p @click="playAudio">Dieren</p>
                    <p @click="playAudio">Boodschappen doen</p>
                    <p @click="playAudio">Wij gaan eten</p>
                    <p @click="playAudio">Speel je mee</p>
                    <p @click="playAudio">Eigen huis</p>
                </div>
                <div class="col-md-3 col-6">
                    <p @click="playAudio">Verkleden</p>
                    <p @click="playAudio">Gevoelens</p>
                    <p @click="playAudio">Dokter dokter</p>
                    <p @click="playAudio">Er op uit</p>
                </div>
            </div>
            
            <div class="row audio mt-2">
                <div class="col-md-1"></div>
                <div class="col-md-8" v-show="title != ''">
                    <p class="is-size-4">u luistert naar: '{{ title }}'</p>
                    <audio ref="player" controls style="width: 100%">
                        <source v-bind:src="audio" type="audio/mpeg" />
                        Uw browser ondersteunt geen audio.
                    </audio>
                </div>
                <div class="col-md-3">
                    <p class="is-size-4">liedteksten</p>
                    <a href="/static/liedjes/Teksten_liedjes_2021.pdf">
                        <button type="button" class="btn btn-outline-dark w-100 mt-1 mb-3">
                            <i class="fa fa-lg fa-file-pdf-o"></i>&nbsp;bekijk / download PDF
                        </button>
                    </a>
                </div>
            </div>
        </div>
        <!-- Modal Component -->
        <!-- <b-modal ref="videoModalRef" size="lg" :hide-header="false" :hide-footer="true" @hide="stopMovie">
<div class="embed-responsive embed-responsive-16by9">
<iframe ref="iframe" class="embed-responsive-item"></iframe>
</div>
		</b-modal>-->
    </div>
</template>

<script>
import top from './Top.vue';
import buttons from './Buttons.vue';

// import Vue from 'vue';
// import BootstrapVue from 'bootstrap-vue';
// Vue.use(BootstrapVue);

// import Vue from "vue";
// import VueYouTubeEmbed from "vue-youtube-embed";
// Vue.use(VueYouTubeEmbed);

export default {
    data: () => ({
        // movie: [
        //     {
        //         href: "http://www.youtube.com/embed/VO4NXpVYX3E",
        //         poster: "http://img.youtube.com/vi/VO4NXpVYX3E/0.jpg"
        //     },
        //     {
        //         href: "http://www.youtube.com/embed/LZntt-e_Ck4",
        //         poster: "http://img.youtube.com/vi/LZntt-e_Ck4/0.jpg"
        //     },
        //     {
        //         href: "http://www.youtube.com/embed/httadOrqpNk",
        //         poster: "http://img.youtube.com/vi/httadOrqpNk/0.jpg"
        //     },
        //     {
        //         href: "http://www.youtube.com/embed/vJ2eutyNAeQ",
        //         poster: "http://img.youtube.com/vi/vJ2eutyNAeQ/0.jpg"
        //     }
        // ],
        title: '',
        audio: '/static/liedjes/Zomer.mp3',
    }),

    components: {
        top,
        buttons,
        // VueYouTubeEmbed
    },

    methods: {
        lrtrim: function(e) {
            var trimmed = e.replace(/^\s+/, '');
            trimmed = trimmed.replace(/\s+$/, '');
            return trimmed;
        },

        playAudio: function(e) {
            this.title = e.target.innerText;
            this.$nextTick(() => {
                console.log(this.title);
                this.title = this.lrtrim(this.title);
                this.audio = '/static/liedjes/' + this.title + '.mp3';
                console.log(this.audio);
                this.$refs.player.load();
                this.$refs.player.play();
            });
        },

        // playMovie(movieNr) {
        //   this.$refs.iframe.src = this.movie[movieNr].href;
        //   this.movieTitle = this.movie[movieNr].title;
        //   this.$refs.videoModalRef.show();
        // },

        // stopMovie() {
        //   // stop movie by setting the iframe's src attribute
        //   this.$refs.iframe.src = "";
        // }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p {
    cursor: pointer;
}

.audio {
    min-height: 100px;
}
</style>
