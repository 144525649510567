<template>
	<div>
		<div class="container my-5">
			<div class="row">
				<div class="col-md-3 col-sm-12 order-md-1 order-sm-2 order-2 colofon">
					<h2>Colofon</h2>
					<p>
						<i>© {{ year }} Copyright Gemeente Enschede</i>
						<br />Het materiaal, de beelden/tekeningen en het concept is alleen vrij te gebruiken voor
						onderwijsdoeleinden. <br />Heb je vragen over het materiaal, mail dan naar:
						pratenmetpim@gmail.com
					</p>

					<h2>Initiatiefnemers</h2>
					<p>Rianne Buist en Leanne Jansen</p>

					<h2>Projectleiders (inhoudelijk)</h2>
					<p>Yvette Reuver (Coördinator), Anouk Lormans en Leanne Jansen</p>

					<h2>Illustratoren</h2>
					<p>
						<a href="https://www.sjusjun.com/nl/">Corona Zschusschen - Sjusjun</a>
					</p>
					<p>
						<a href="http://www.studioappeltjesgroen.com/"
							>Linda van Ballegooijen - studio Appeltjesgroen</a
						>
					</p>
					<p>
						<a href="http://www.3link.be">Katrien van Schuylenbergh - 3link</a>
					</p>

					<h2>Projectleider (vormgeving)</h2>
					<p>
						<a href="http://www.queenie.nl">Petra ter Keurs - Queenie Productions</a>
					</p>

					<h2>Programmeur website</h2>
					<p>
						<a href="http://www.focussing.nl">Raymond Verbruggen - Focussing Borne</a>
					</p>

					<h2>Liedjes ‘Zingen met Pim’</h2>
					<p>
						<a href="https://tessakortenbach.nl/">Geschreven en ingezongen door Tessa Kortenbach</a>
					</p>

					<h2>Met dank aan</h2>
					<p>Gemeente Enschede: <br />Agnes Eshuis en Hans Koier</p>
					<!-- <p>
						Werkgroep doorgaande lijn NT2: <br />Rianne Buist, Leanne Jansen, Anouk Karpati, Ingrid
						Klootwijk, Anita Oosterholt, Yvette Reuver, Natalie ten Vergert en Havva Yasar
					</p>
					<p>
						Team deeltijdschakelklassen Enschede: <br />Marije Bakker, Mouth Brilhuis, Karlijn Glandrup,
						Karin de Lange, Joke Lorijn, Marijke Masselink, Alies Oosterwijk, Nicole Schoorlemmer en Anglia
						Vos
					</p>
					<p>
						Team coaches VVE: <br />Miranda Brouwer, Monique Giebels, Marieke Goïnga, Nicole Hempen, Melanie
						Jongbloed, Yvonne Kroese, Annemarie Maathuis, José de Man, Jenet Marsman, Jolanda Reith, Titia
						van der Sommen, Inge Tigelaar en Marga van der Veen
					</p> -->
				</div>
				<div class="col-md-9 col-sm-12 order-ms-2 order-sm-1 order-1">
					<h2>Voorwoord</h2>
					<p>
						Ieder kind heeft taal nodig om zich te kunnen uiten. Woorden om te kunnen spelen, woorden om
						gesprekken te voeren en woorden om aan te geven wat je nodig hebt.
					</p>
					<p>
						Woorden in de taal van de omgeving waar je bent. In de peuteropvang, het kinderdagverblijf en de
						basisschool is dat de Nederlandse taal. Woordenschat is dan ook een belangrijke basis voor de
						hele ontwikkeling van een kind.
					</p>
					<p>
						Alle Kinderopvangorganisaties en basisscholen in Enschede maken deel uit van een Integraal
						KindCentrum (IKC). Binnen de IKC’s wordt samengewerkt om een doorgaande ontwikkelingsen zorglijn
						te realiseren. In de doorgaande lijn is het van belang dat er voor kinderen een aantal
						herkenbare punten zijn die zij tegenkomen in de peutergroep en later op de basisschool. Samen
						met pedagogisch medewerkers, leerkrachten en coaches VVE is Praten met Pim ontwikkeld. Hierbij
						was de opdracht vanuit de gemeente Enschede om materialen te ontwikkelen voor peuters en
						kleuters gericht op woordenschat. Een goede basis voor ieder kind en een intensieve aanpak voor
						kinderen met een verminderde taalontwikkeling.
					</p>
					<p>
						De kinderen in de Enschedese peuterspeelzalen, kinderdagverblijven en groepen 1 en 2 van de
						basisschool hebben inmiddels kennis gemaakt met Pim. Pim is een jongen die in dit programma
						symbool staat voor het leren van woorden.
					</p>
					<p>
						Praten met Pim is een woordenschatprogramma waarbij ieder kind de mogelijkheid krijgt zich te
						leren uiten. Daarmee wordt een stevige basis gelegd voor een leven lang leren. Ieder kind mag
						immers leren wat hij kan leren.
					</p>
					<p>
						Praten met Pim voorziet in materialen die passen bij een beredeneerd aanbod woordenschat voor
						peuters en kleuters. Het materiaal is methode-onafhankelijk en gerelateerd aan de meest recente
						SLO-inhouden (versie 2017-2018).
					</p>
					<p>
						De intensiviteit van het woordenschataanbod is voor ieder kind anders. Eén ding is echter voor
						ieder kind gelijk; leren is kinderspel. Oftewel; spelen, spelen en nog meer spelen! Het
						doorleven van de taal door te voelen, te zien, te horen en te spelen. Uiteindelijk zijn de
						illustraties van Pim de vertaling naar het platte vlak. Een duidelijk beeld dat kinderen helpt
						de woorden te onthouden.
					</p>
					<p>
						Praten met Pim is ontwikkeld in nauwe samenwerking met pedagogisch medewerkers en leerkrachten
						groep 1 en 2. Hiermee sluit het programma aan bij hun wensen. Een uniek project waarbij de
						doorgaande lijn NT2 (Nederlands als Tweede Taal) binnen de Enschedese Integrale Kindcentra een
						heel eigen gezicht heeft gekregen. Wij wensen u veel praat- en speelplezier met Pim!
					</p>
					<p>Leanne Jansen en Rianne Buist</p>
					<img src="../assets/gemeente-enschede.png" class="img-fluid" alt="logo enschede" />
					<br />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import top from './Top.vue';

export default {
	components: {
		top,
	},

	data: () => ({
		year: new Date().getFullYear(),
	}),

	created() {
		console.clear();
	},

	methods: {},
};
</script>

<style scoped>
.container {
	text-align: left;
}

.colofon {
	font-size: 0.85rem;
	color: #03965b;
}

h2 {
	font-size: 1.25rem;
}

.colofon h2 {
	font-size: 1rem;
}

a {
	color: #03965b;
}

ul {
	padding-inline-start: 15px;
}
</style>
