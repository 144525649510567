<template>
	<div>
		<buttons></buttons>
		<br />

		<!------------------------------------------------------------------------------------>
		<!-- search bar -->
		<!------------------------------------------------------------------------------------>
		<div class="jumbotron jumbotron-fluid">
			<div class="container">
				<div class="row">
					<div class="col-md-8 offset-md-3 text-left">
						<div class="input-group mb-3">
							<div class="input-group-prepend">
								<span class="input-group-text">
									<i class="fa fa-search"></i>
								</span>
							</div>
							<input
								type="text"
								class="form-control"
								placeholder="type een woord"
								v-model="searchItem"
								@input="searchHandler()"
							/>
						</div>
						<input type="checkbox" class="form-check-input ml-0" id="exampleCheck1" v-model="checkbox" @change="searchHandler()" />
						<label class="form-check-label ml-4" for="exampleCheck1">Zoek op deel van woord</label>
						<label class="form-check-label float-right" v-show="searchItem.length > 0"
							>Aantal gevonden: {{ nrFoundImages }}</label
						>
					</div>
				</div>
			</div>
		</div>

		<!------------------------------------------------------------------------------------>
		<!-- main container -->
		<!------------------------------------------------------------------------------------>

		<div class="container">
			<!------------------------------------------------------------------------------------>
			<!-- buttons -->
			<!------------------------------------------------------------------------------------>
			<div class="row">
				<div class="col-md-8 offset-md-3">
					<div class="row align-items-center">
						<div class="col" :class="{ 'btn-red fill': typeNr == 0 }">
							<p
								class="is-size-5 my-3 pointer"
								:class="{ 'text-white font-weight-bold': typeNr == 0 }"
								@click="setType(0)"
							>
								{{ types[0] }}
							</p>
						</div>
						<div class="col" :class="{ 'btn-red fill': typeNr == 1 }">
							<p
								class="is-size-5 my-3 pointer"
								:class="{ 'text-white font-weight-bold': typeNr == 1 }"
								@click="setType(1)"
							>
								{{ types[1] }}
							</p>
						</div>
						<div class="col" :class="{ 'btn-red fill': typeNr == 2 }">
							<p
								class="is-size-5 my-3 pointer"
								:class="{ 'text-white font-weight-bold': typeNr == 2 }"
								@click="setType(2)"
							>
								{{ types[2] }}
							</p>
						</div>
						<div class="col" :class="{ 'btn-red fill': typeNr == 3 }">
							<p
								class="is-size-5 my-3 pointer"
								:class="{ 'text-white font-weight-bold': typeNr == 3 }"
								@click="setType(3)"
							>
								{{ types[3] }}
							</p>
						</div>
					</div>
				</div>
			</div>

			<!------------------------------------------------------------------------------------>
			<!-- instruction -->
			<!------------------------------------------------------------------------------------>
			<div class="row">
				<div class="col-md-8 offset-md-3 text-left">
					<p class="font-weight-bold">Gebruiksaanwijzing</p>
					<p>
						Selecteer een categorie en daarna een thema. Selecteer dan het aantal kaarten dat je wilt
						downloaden. Ben je klaar? Klik dan op ‘creëer PDF’, daarna kun je het bestand downloaden. De
						kaarten kunnen alleen per categorie en per thema gedownload worden. Na het downloaden kun je het
						bestand afdrukken. Veel plezier met de woordkaarten/ vertelkaarten/ memory/ bingo!
					</p>
				</div>
			</div>

			<!------------------------------------------------------------------------------------>
			<!-- row user inputs and previews -->
			<!------------------------------------------------------------------------------------>

			<div class="row">
				<!------------------------------------------------------------------------------------>
				<!-- categories -->
				<!------------------------------------------------------------------------------------>

				<div class="col-md-3 text-left">
					<p class="font-weight-bold">Categorieën:</p>
					<ul>
						<li
							class="pointer"
							v-for="(cat, index) in categories"
							:style="cat"
							@click="setCategory(cat, index)"
							:key="cat.name"
						>
							{{ cat.name }}
						</li>
					</ul>
				</div>

				<!------------------------------------------------------------------------------------>
				<!-- preview pane -->
				<!------------------------------------------------------------------------------------>

				<div class="pl-2" v-for="(col, c) in previewCols" :key="col">
					<div class="pt-2" v-for="(row, r) in previewRows" :key="row">
						<!-- <div class="col m-0 p-0"> -->
						<div class="card square" :class="{ 'card-selected': modulus > r * previewCols + c }">
							<!-- r{{r}} c{{c}} -->
						</div>
						<!-- </div> -->
					</div>
				</div>

				<!------------------------------------------------------------------------------------>
				<!-- selection counter - printing -->
				<!------------------------------------------------------------------------------------>

				<div class="col text-left">
					<p v-show="nrSelectedImages > 0">
						{{ nrSelectedImages }}
						<span v-show="nrSelectedImages == 1">woord geselecteerd</span>
						<span v-show="nrSelectedImages > 1">woorden geselecteerd</span>
					</p>
					<p v-show="nrPages > 0">
						{{ nrPages }} A4 blad
						<span v-show="nrPages > 1">en</span>
					</p>
					<!-- <button
						type="button"
						class="btn btn-outline-dark w-100"
						@click="createPrint()"
						v-show="showPrintButton()"
					>
						<i class="fa fa-lg fa-print"></i>&nbsp;print pagina(s)
					</button> -->
					<button
						type="button"
						class="btn btn-outline-dark w-100 mt-1"
						@click="createPdf()"
						v-show="showPrintButton()"
					>
						<i class="fa fa-lg fa-file-pdf-o"></i>&nbsp;creëer PDF
					</button>
				</div>

				<div class="col-md-4 text-left">
					<!-- memory layout settings -->
					<!-- <div class="control" v-show="type == types[2]">
						<p>Kies aantal woorden</p>
						<input type="radio" name="answer" @click="setMemoryNrWords(8)" />
						<label class="radio">8 woorden</label>
						<br />
						<input type="radio" name="answer" @click="setMemoryNrWords(12)" />
						<label class="radio">12 woorden</label>
						<br />
						<input type="radio" name="answer" @click="setMemoryNrWords(16)" />
						<label class="radio">16 woorden</label>
					</div> -->
					<!-- bingo layout settings -->
					<div class="control" v-show="type == types[3]">
						<!-- <p>Aantal woorden</p>
						<div class="select">
							<select v-model="nrWords">
								<option>Selecteer hier</option>
								<option>12</option>
								<option>13</option>
								<option>14</option>
								<option>15</option>
								<option>16</option>
							</select>
						</div>
						<br />
						<br /> -->
						<p>Aantal leerlingen</p>
						<input
							class="input"
							type="number"
							min="1"
							placeholder="Voer het aantal leerlingen in"
							v-model.number="nrPlayers"
						/>
						<br />
						<br />
						<p>Selecteer ten minste 6 kaarten</p>

						<button
							type="button"
							class="btn btn-outline-dark"
							v-show="showBingoGenerateButton()"
							@click="generateBingoImages"
						>
							Genereer bingo kaarten
						</button>
					</div>
				</div>
			</div>
			<br />

			<!------------------------------------------------------------------------------------>
			<!-- row with coloured dots -->
			<!------------------------------------------------------------------------------------>

			<div class="row">
				<div
					class="col-md-9 offset-md-3 dots-bar"
					:class="[
						{ 'dots-p': category == 'P' },
						{ 'dots-k': category == 'K' },
						{ 'dots-u': category == 'U' },
					]"
				></div>
			</div>

			<!------------------------------------------------------------------------------------>
			<!-- row with themes and cards -->
			<!------------------------------------------------------------------------------------>

			<div class="row">
				<!-- theme selector -->
				<div class="col-md-3 text-left">
					<p class="font-weight-bold">Thema's:</p>
					<ul>
						<li
							class="pointer"
							v-for="(theme, index) in themes"
							:key="theme.thema_nr"
							:class="{ underline: theme.active, disabled: theme.present == '' }"
							@click="setTheme(index + 1)"
						>
							{{ index + 1 }}. {{ theme.theme_naam }}
						</li>
					</ul>
					<p class="pointer" @click="selectAllCards()">alles selecteren</p>
				</div>

				<!-- cards -->
				<div class="col-md-9">
					<div class="row">
						<div class="col-sm-4 col-6 p-2" v-for="image in images" :key="image.id">
							<div
								class="card h-100"
								:class="{ 'card-selected': image.selected }"
								@click="selectCard(image)"
							>
								<div class="card-img-top">
									<img class="img-fluid" :src="image.imageName" />
								</div>
								<div class="card-body">
									<img class="img-fluid" :src="image.dots" alt="stippels" />
									<p class="medium" v-if="typeNr != 1">
										<i
											:style="{ color: dotColor }"
											class="fa fa-circle"
											v-if="image.lidwoord == 'het'"
										></i>
										{{ ' ' + image.lidwoord + ' ' + image.woord }}
									</p>
									<div v-if="typeNr == 1">
										<div v-for="z in image.zin" :key="z.id">
											<p class="medium" v-html="z"></p>
										</div>
									</div>
								</div>
							    <span v-if="searching">Thema: {{ themes[image.thema_nr - 1].theme_naam }}</span>
							</div>
							<!-- <v-icon
                                v-if="typeNr==0"
								:fill="catColor"
								name="volume-up"
								class="icon"
								scale="1.5"
								@click="playSound(image.imageName)"
							/> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import top from './Top.vue';
import buttons from './Buttons.vue';
import ax from '../js/axios.js';
import _ from 'underscore';
import 'vue-awesome/icons/volume-up';
import Icon from 'vue-awesome/components/Icon';

export default {
	data: () => ({
		table: 'thema',
		types: ['woordkaarten', 'vertelkaarten', 'memory', 'bingo'],
		type: '',
		typeNr: 0,

		allItems: [],
		searchItem: '',
		nrFoundImages: 0,
		checkbox: false,

		themes: [],
		theme: 0,
		categories: [
			{
				name: 'peuters',
				abbr: 'P',
				active: false,
				color: '#c42737',
			},
			{
				name: 'kleuters',
				abbr: 'K',
				active: false,
				color: '#459dd7',
			},
			{
				name: 'uitbreiding',
				abbr: 'U',
				active: false,
				color: '#03965b',
			},
		],
		category: '',
		catColor: '#03965b',
		dotColor: '#e7344c',

		previewCols: 0,
		previewRows: 0,
		nrImagesPerPage: 0,

		images: [],
		selectedImages: [],
		nrSelectedImages: 0,
		modulus: 0,
		nrPages: 0,
		// nrWords: 0,

		nrPlayers: 1,
		bingoCardsGenerated: false,
		marginAfterRow: 0,

		dots: '',
		printSettings: {},

		searching: false,
	}),

	components: {
		top,
		buttons,
		'v-icon': Icon,
	},

	created() {
		// console.clear();
		// console.log('woorden.vue created');
		this.setType(0);

		ax.getData(this.table)
			// get themes
			.then((response) => {
				// console.table(response);
				this.themes = response;
			})
			.catch((e) => {
				console.log(e);
			});
	},

	methods: {
		// createPrint: function () {
		// 	this.$router.push({
		// 		name: 'Print',
		// 		params: {
		// 			printSettings: this.printSettings,
		// 		},
		// 	});
		// },

		playSound: function (e) {
			console.log('play', e.replace('.png', '.mp4'));
			var audio = new Audio(e.replace('.png', '.mp4'));
			audio.play();
		},

		createPdf: function () {
			this.$router.push({
				name: 'Pdf',
				params: {
					printSettings: this.printSettings,
				},
			});
		},

		generateBingoImages: function () {
			// console.log(this.nrWords, this.nrPlayers);

			// min and max for random number
			var min = 0;
			var max = this.nrSelectedImages - 1;

			// console.log('min', min);
			// console.log('max', max);

			var selNrs = [];
			for (var i = 0; i < this.nrPlayers; i++) {
				var s = '';
				var j = 0;
				var sel = [];
				while (j < 6) {
					var rnd = Math.floor(Math.random() * (max - min + 1) + min);
					if (!sel.includes(rnd)) {
						sel.push(rnd);
						selNrs.push(rnd);
						s += rnd + ' ';
						j++;
					}
				}
				console.log(s);
			}

			var selCards = [];
			for (var i = 0; i < selNrs.length; i++) {
				selCards.push(this.selectedImages[selNrs[i]]);
			}
			this.printSettings.images = selCards;
			this.printSettings.nrPages = Math.ceil(selCards.length / this.nrImagesPerPage);
			console.log(selCards);

			this.bingoCardsGenerated = true;
			// this.$router.push('/print')
		},

		showBingoGenerateButton: function () {
			// var show = true;
			// var nr = this.nrWords;

			// // if less images present than use that for maximum
			// if (this.images.length < nr) nr = this.images.length;

			// bingo - at least 6 images must be selected
			let show = this.nrSelectedImages >= 6 && this.nrPages > 0;

			return show;
		},

		showPrintButton: function () {
			// var show = true;

			// memory show when nrwords are right
			// if (this.type == this.types[2]) {
			// 	if (this.nrWords == this.nrSelectedImages) show = true;
			// 	else show = false;
			// }

			// show when nrpages > 0
			let show = this.nrPages > 0; // && show;

			// show when type is bingo and cards generated
			if (this.type == this.types[3]) {
				show = this.bingoCardsGenerated && show;
			}

			return show;
		},

		// setMemoryNrWords: function (nrWords) {
		// 	// console.log(nrWords);
		// 	this.nrWords = nrWords;
		// },

		selectCard: function (img) {
			// console.log(img);
			img.selected = !img.selected;

			if (img.selected) {
				this.selectedImages.push(img);
				this.nrSelectedImages++;
				if (this.typeNr == 1) {
					this.selectedImages.push(img);
					this.nrSelectedImages++;
				}
			} else {
				this.selectedImages.pop(img);
				this.nrSelectedImages--;
				if (this.typeNr == 1) {
					this.selectedImages.pop(img);
					this.nrSelectedImages--;
				}
			}

			this.nrPages = Math.ceil(this.nrSelectedImages / this.nrImagesPerPage);
			this.modulus = this.nrSelectedImages % this.nrImagesPerPage;

			this.printSettings = {
				images: this.selectedImages,
				dots: this.dots,
				catColor: this.catColor,
				nrPages: this.nrPages,
				nrImagesPerPage: this.nrImagesPerPage,
				nrCols: this.previewCols,
				nrRows: this.previewRows,
				marginAfterRow: this.marginAfterRow,
				typeNr: this.typeNr,
			};

			this.bingoCardsGenerated = false;
		},

		selectAllCards: function () {
			this.images.forEach((image) => {
				this.selectCard(image);
			});
		},

		imageLink: function (u) {
			let imagePath = '';
			let word = '';

			if (this.typeNr != 1) {
				imagePath = '/static/woordkaarten/';
				word = u.woord;
			} else {
				imagePath = '/static/vertelkaarten/';
				let pl = u.plaatjes.split(',');
				word = pl[0];
			}

			let link = imagePath + u.thema_nr + '/' + u.categorie + '/' + word + '.png';

			return link;
		},

		handleSentences: function (sent, target) {
			let style1, style2;

			// style definitions for print
			if (target == 'screen') {
				style1 = '<span style="color:' + this.catColor + '">';
				style2 = '</span>';
			}

			// style definitions for pdf
			if (target == 'pdf') {
				style1 = '<';
				style2 = '>';
			}

			// get sentences, trim, add dot to end
			let sentences = sent.split('.');
			sentences = _.without(sentences, '', ' ');
			sentences = _.map(sentences, function (s) {
				let sn = s.trim();
				if (sn.slice(-1) != '?' && sn.slice(-1) != '!') sn += '.';

				sn = sn.replace(/\[/g, style1);
				sn = sn.replace(/]/g, style2);

				return sn;
			});

			if (target == 'screen') {
				// fill with empty sentences upto 3
				for (var i = sentences.length; i < 3; i++) {
					sentences.push('&nbsp;');
				}
			}

			return sentences;
		},

		searchHandler: function () {
			this.searching = true;

			// get all images once to be ready for searching
			if (this.allItems.length == 0) {
				let url;
				if (this.typeNr == 1) {
					url = '/zinnen/';
				} else {
					url = '/data/';
				}

				this.images = [];
				this.allItems = [];
				ax.getData(url)
					.then((response) => {
						if (response.length > 0) {
							response.forEach((u) => {
								var imageName = this.imageLink(u);
								var newImage = this.clone(u);

								// handle special characters
								// on some browsers files with the following characters are not shown
								if (imageName.indexOf('è') != -1) {
									imageName = imageName.replace('è', 'e');
								}
								if (imageName.indexOf('ë') != -1) {
									imageName = imageName.replace('ë', 'e');
								}
								if (imageName.indexOf('ö') != -1) {
									imageName = imageName.replace('ö', 'o');
								}

								newImage.imageName = imageName;
								newImage.selected = false;
								newImage.dots = require('@/assets' + '/dots' + u.categorie + '.png');

								if (this.typeNr == 1) {
									newImage.zin = this.handleSentences(u.zin, 'screen');
									newImage.zinPdf = this.handleSentences(u.zin, 'pdf');
								}

								this.allItems.push(newImage);
							});
						}
					})
					.catch((e) => {
						console.log(e);
					});
			} else {
				if (this.searchItem.length >= 2) {
					if (this.searchItem == '**') {
						this.images = this.allItems;
						this.nrFoundImages = this.images.length;
					} else {
						// search for words
						var foundImages = [];
						this.searchItem = this.searchItem.toLowerCase();
						this.allItems.forEach((u) => {
							if (this.typeNr == 1) {
								u.zin.forEach((f) => {
									let srch = f.toLowerCase();
									if (srch.indexOf(this.searchItem) != -1) {
										foundImages.push(u);
									}
								});
							} else {
								if (this.checkbox) {
									if (u.woord.toLowerCase().indexOf(this.searchItem) != -1) {
										foundImages.push(u);
									}
								} else {
									if (u.woord.toLowerCase() == this.searchItem) {
										foundImages.push(u);
									}
								}
							}
						});

						this.images = foundImages;
						this.nrFoundImages = foundImages.length;
					}
				}
			}
		},

		clone: function (u) {
			return JSON.parse(JSON.stringify(u));
		},

		getImages: function (theme, category) {
			// console.log('getImages', theme, category);
			let url = '';
			if (this.typeNr != 1) {
				url = '/data/thema_nr/' + theme;
			} else {
				url = '/zinnen/thema_nr/' + theme;
			}

			this.images = [];
			ax.getData(url)
				.then((response) => {
					if (response.length > 0) {
						response.forEach((u) => {
							if (u.categorie == category) {
								var imageName = this.imageLink(u);
								var newImage = this.clone(u);

								// handle special characters
								// on some browsers files with the following characters are not shown
								if (imageName.indexOf('è') != -1) {
									imageName = imageName.replace('è', 'e');
								}
								if (imageName.indexOf('ë') != -1) {
									imageName = imageName.replace('ë', 'e');
								}
								if (imageName.indexOf('ö') != -1) {
									imageName = imageName.replace('ö', 'o');
								}

								newImage.imageName = imageName;
								newImage.selected = false;
								newImage.dots = require('@/assets' + '/dots' + u.categorie + '.png');
								if (this.typeNr == 1) {
									newImage.zin = this.handleSentences(u.zin, 'screen');
									newImage.zinPdf = this.handleSentences(u.zin, 'pdf');
								}
								this.images.push(newImage);
							}
						});
					}
					// console.log(this.images);
				})
				.catch((e) => {
					console.log(e);
				});
		},

		setType: function (typeNr) {
			// console.log(typeNr);
			this.type = this.types[typeNr];
			if ((typeNr == 1 && this.typeNr != 1) || (typeNr != 1 && this.typeNr == 1)) {
				this.images = [];
				this.selectedImages = [];
			}
			this.typeNr = typeNr;

			switch (typeNr) {
				case 0:
				case 1:
					// woordkaarten vertelkaarten
					this.previewCols = 2;
					this.previewRows = 3;
					break;
				case 2:
				case 3:
					// memory bingo
					this.previewCols = 3;
					this.previewRows = 4;
					break;
			}
			this.nrImagesPerPage = this.previewCols * this.previewRows;
			this.nrPages = Math.ceil(this.nrSelectedImages / this.nrImagesPerPage);
			this.modulus = this.nrSelectedImages % this.nrImagesPerPage;
			this.marginAfterRow = typeNr == 3 ? 2 : 0;
			this.searchItem = '';
			this.allItems = [];
		},

		resetCounters: function () {
			this.nrSelectedImages = 0;
			this.selectedImages = [];
			this.modulus = 0;
			this.nrPages = 0;
			this.searching = false;
		},

		setCategory: function (cat, index) {
			// console.log(index)
			// only selected theme becomes active
			this.categories.forEach((c) => {
				c.active = false;
			});
			this.categories[index].active = true;

			// set category
			this.category = cat.abbr;
			this.dots = require('@/assets' + '/dots' + cat.abbr + '.png');
			if (this.theme >= 1) this.getImages(this.theme, this.category);
			this.catColor = cat.color;

			// reset counters
			this.resetCounters();
		},

		setTheme: function (theme) {
			// only selected theme becomes active
			this.themes.forEach((t) => {
				t.active = false;
			});
			this.themes[theme - 1].active = true;

			// set theme
			this.theme = theme;
			this.getImages(this.theme, this.category);

			// reset counters
			this.resetCounters();
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
	color: black;
}

.jumbotron-fluid {
	/*padding: 40px 0;*/
	background-color: #dddddd;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.underline {
	text-decoration: underline;
}

.disabled {
	display: none;
}

.card-image {
	padding-top: 20px;
}

.card-content {
	padding-top: 0;
}

div.col a {
	color: #4a4a4a;
}

div.col-3 a:hover {
	text-decoration: underline;
}

div.col-3 ul li:hover {
	text-decoration: underline;
}

.dots-bar {
	/* border: solid 1px blue; */
	height: 20px;
	background-repeat: repeat-x;
	background-size: 20px;
}

.dots-p {
	background-image: url('../assets/stippel_P.svg');
}

.dots-k {
	background-image: url('../assets/stippel_K.svg');
}

.dots-u {
	background-image: url('../assets/stippel_U.svg');
}

.color-p {
	color: '#c42737';
}

.color-k {
	color: '#459dd7';
}

.color-u {
	color: '#03965b';
}

.card-img-top {
	padding: 20px 20px 0 20px;
}

.card-body {
	padding: 0 20px 0 20px;
	color: '#459dd7';
}

.card-selected {
	background-color: #e9e9eb;
}

.medium {
	font-weight: 400;
}

.square {
	border: solid 1px red;
	width: 60px;
	height: 60px;
}

.pointer {
	cursor: pointer;
}

.icon {
	position: absolute;
	right: 1rem;
	top: 1rem;
	z-index: 10;
	cursor: pointer;
}
</style>
