<template>
	<div>
		<div class="container">
			<div class="row my-5">
				<div class="col-5" v-show="pageCount">
					<button type="button" class="btn btn-outline-dark" :disabled="currentPage <= 1" @click="prevPage()">
						<i class="fa fa-lg fa-angle-left"></i>
					</button>
					&nbsp; pagina {{ currentPage }} van {{ pageCount }} &nbsp;
					<button
						type="button"
						class="btn btn-outline-dark"
						:disabled="currentPage == pageCount"
						@click="nextPage()"
					>
						<i class="fa fa-lg fa-angle-right"></i>
					</button>
					<br />
					<br />
					<!-- <button type="button" class="btn btn-outline-dark" @click="showPdf()">genereer</button> -->
					<button type="button" class="btn btn-outline-dark" @click="savePdf()">
						<i class="fa fa-lg fa-download"></i> downloaden
					</button>
				</div>
				<div class="col" v-show="!pageCount">
					<p>
						{{ pdfMessage }}
					</p>
				</div>
				<div class="col border shadow" v-show="pageCount">
					<pdf
						v-bind:src="pdfString"
						:page="currentPage"
						@num-pages="pageCount = $event"
						@page-loaded="currentPage = $event"
					></pdf>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pdf from 'vue-pdf';
import top from './Top.vue';

export default {
	components: {
		pdf,
		top,
	},

	props: ['printSettings'],

	data: () => ({
		doc: null,
		pdfString: '',
		images: [],
		color: '',
		nrPages: 2,
		nrCols: 2,
		nrRows: 3,
		marginAfterRow: 0,
		typeNr: 0,
		// 0: woordkaarten
		// 1: vertelkaarten consolideerkaarten cooperatieve_werkvormen
		// 2: memory
		// 3: bingo

		consolideerkaarten: false,
		cooperatieve_werkvormen: false,

		currentPage: 1,
		pageCount: 0,
		pdfMessage: 'PDF-bestand wordt gegenereerd...',
		pdfReady: false,
	}),

	created() {
		console.clear();
		// console.log('printSettings:', this.printSettings);

		if (this.printSettings) {
			this.images = this.printSettings.images;
			this.typeNr = this.printSettings.typeNr;
			this.nrPages = this.printSettings.nrPages;
			this.nrCols = this.printSettings.nrCols;
			this.nrRows = this.printSettings.nrRows;
			this.color = this.printSettings.catColor;
			this.marginAfterRow = this.printSettings.marginAfterRow;
			this.consolideerkaarten = this.printSettings.consolideerkaarten;
			this.cooperatieve_werkvormen = this.printSettings.cooperatieve_werkvormen;

			var getImageFromUrl = function (images, callback) {
				var i = 0;
				var timer = setInterval(() => {
					// console.log(i, images[i].imageName);
					var img = new Image();

					img.onError = function () {
						alert('Cannot load image: "' + url + '"');
					};
					img.onload = function () {
						// console.log(img);
						imgLoaded.push(img);

						i++;
						if (i == images.length) {
							clearInterval(timer);
							callback(1, imgLoaded);
						}
					};
					img.src = images[i].imageName;
				}, 20);
			};

			var imgLoaded = [];
			getImageFromUrl(this.images, this.generatePdf);
		} else {
			// no images selected - go back
			this.$router.go(-1);
		}
	},

	methods: {
		prevPage: function () {
			if (this.currentPage > 1) {
				this.currentPage--;
			}
		},

		nextPage: function () {
			if (this.currentPage < this.pageCount) {
				this.currentPage++;
			}
		},

		wordLength: function (doc, word) {
			let length = 0;
			if (word == '') {
				length = 0;
			} else {
				// last character space to dummy character
				// if (word.slice(-1) == ' ') {
				//     word = word.slice(0, -1) + 'm';
				// }
				// length = doc.getTextDimensions(word).w / (72 / 25.6) * 1.12;
			}

			// console.log('|' + word + '|', word.length, length.toFixed(1));

			length = (doc.getStringUnitWidth(word) * 18) / (72 / 25.6);
			return length;
		},

		splitTextToSize: function (vm, doc, line, max) {
			let lines = [];
			let z = line.split(' ');

			let p = 0;
			let quit = false;
			do {
				let zz = '';
				let maxlength = false;
				let eos = false;
				do {
					// add words until max
					zz += z[p] + ' ';
					p++;

					maxlength = vm.wordLength(doc, zz) >= max;
					eos = p >= z.length;
				} while (!maxlength && !eos);

				if (maxlength) {
					p--;
					zz = zz.replace(z[p] + ' ', '');
				}

				lines.push(zz);

				if (p == z.length) {
					// line fits
					quit = true;
				}
			} while (!quit);

			return lines;
		},

		generatePdf: function (action, imgdata) {
			const WIDTH = 210;
			const HEIGHT = 297;
			const MARGIN = 20;

			const IMG_ASPECT_RATIO = 576 / 700;
			const IMG_MARGIN = 8;

			const DOTS_HEIGHT_PERC = 0.85;
			const DOTS_ASPECT_RATIO = 56 / 1012;
			const DOTS_MARGIN = 3;

			const WORD_HEIGHT_PERC = 0.95;
			const LINE_XMARGIN = 5;
			let LINE_HEIGHT = 8;
			if (this.cooperatieve_werkvormen) LINE_HEIGHT = 6;

			// create new pdf document
			this.doc = new jsPDF();
			var doc = this.doc;
			doc.viewerPreferences({
				FitWindow: true,
			});

			// show available fonts
			// console.log(doc.getFontList());

			// var widths = [];
			// for (let i = 0; i < this.images.length; i++) {
			//     var c1 = doc.getStringUnitWidth(this.images[i].woord) * 24 / (72 / 25.6);
			//     var c2 = doc.getTextDimensions(this.images[i].woord).w / (72 / 25.6)
			//     console.log(c1.toFixed(3), c2.toFixed(3), (c1 / c2).toFixed(3));
			//
			//     widths.push(c1);
			// }

			// add font except for vertelkaarten consolideerkaarten cooperatieve_werkvormen
			// for jsPDF 1.4.0 wordlength cannot be calculated with custom fonts
			// https://www.devlinpeck.com/tutorials/jspdf-custom-font
			if (this.typeNr != 1) {
				doc.addFont('Quicksand-Regular.ttf', 'custom', 'normal');
				doc.setFont('custom');
			}

			// calculate cell parameters
			let cellWidth = (WIDTH - 2 * MARGIN - this.marginAfterRow) / this.nrCols;
			let cellHeight = (HEIGHT - 2 * MARGIN) / this.nrRows;

			// cells
			let i = 0;
			for (let page = 0; page < this.nrPages; page++) {
				// a4 outer border when viewed on screen
				// if (action == 1) {
				//     doc.setDrawColor(100);
				//     doc.rect(0, 0, WIDTH, HEIGHT);
				// }

				let y = MARGIN;
				for (let row = 0; row < this.nrRows; row++) {
					if (this.marginAfterRow != 0 && row == this.marginAfterRow) y += 10;

					doc.setDrawColor(200);
					let x = MARGIN;

					for (let col = 0; col < this.nrCols; col++) {
						if (i < this.images.length) {
							// card
							doc.rect(x, y, cellWidth, cellHeight);

							// image
							if (!(this.typeNr == 1 && col % 2 == 1)) {
								let imgWidth = cellWidth - 2 * IMG_MARGIN;
								let imgHeight = imgWidth * IMG_ASPECT_RATIO;
								let imgX = x + IMG_MARGIN;
								let imgY = y + IMG_MARGIN;
								try {
									// add image
									doc.addImage(imgdata[i], 'png', imgX, imgY, imgWidth, imgHeight);

									// add title
									if (this.consolideerkaarten || this.cooperatieve_werkvormen) {
										let title;
										if (this.consolideerkaarten) {
											title = this.images[i].zinPdf[0];
										}
										if (this.cooperatieve_werkvormen) {
											title = this.images[i].plaatje;
										}
										title = title.replace('<', '');
										title = title.replace('>', '');
										doc.setFontSize(18);
										let xWord = x + cellWidth / 2;
										let yWord = imgY + imgHeight + 17.5;
										doc.setTextColor(this.color);
										doc.text(title, xWord, yWord, null, null, 'center');
										doc.setTextColor(0);
									}
								} catch (err) {
									console.log(this.images[i].imageName);
									console.log(err);

									// let newname = this.images[i].imageName.replace('/static/woordkaarten-png/','/static/woordkaarten/');
									// newname = newname.replace('/static/vertelkaarten-png/','/static/vertelkaarten/');
									// newname = newname.replace('.png', '.jpeg');
									// doc.addImage(newname, 'jpeg', imgX, imgY, imgWidth, imgHeight);

									let theme = 'thema: ' + this.images[i].thema_nr;
									let cat = 'categorie: ' + this.images[i].categorie;
									let imm = this.images[i].imageName.split('/');
									let errMsg = [
										'fout in bestand:',
										imm[imm.length - 1],
										'',
										theme,
										cat,
										'',
										'zie console voor info',
									];
									doc.text(errMsg, imgX, imgY);
								}
								// console.log('imgWidth', imgWidth);
							}

							// sentences
							if (this.typeNr == 1 && col % 2 == 1) {
								// set font size
								doc.setFontSize(18);

								// set font size for cooperatieve_werkvormen
								if (this.cooperatieve_werkvormen) {
									doc.setFontSize(13);
								}

								// loop sentences
								let yLine = y + IMG_MARGIN + LINE_HEIGHT;
								for (let l = 0; l < this.images[i].zinPdf.length; l++) {
									// check sentence length against max width
									let zpdf = this.images[i].zinPdf[l];
									var zsplits = this.splitTextToSize(this, doc, zpdf, cellWidth - LINE_XMARGIN);

									// var zsplits = [];
									// zsplits.push(zpdf); //doc.splitTextToSize(zpdf, cellWidth - LINE_XMARGIN);

									// loop lines of sentences
									for (let zcount = 0; zcount < zsplits.length; zcount++) {
										let xLine;
										if (this.consolideerkaarten || this.cooperatieve_werkvormen) {
											xLine = x + cellWidth / 2;
										} else {
											xLine = x + LINE_XMARGIN;
										}

										let z = zsplits[zcount];
										let zz = '';

										// switch color at marker positions
										for (let p = 0; p < z.length; p++) {
											if (z[p] != '<' && z[p] != '>') {
												zz += z[p];
											} else {
												if (this.consolideerkaarten || this.cooperatieve_werkvormen) {
													doc.text(xLine, yLine, zz, null, null, 'center');
												} else {
													doc.text(xLine, yLine, zz);
												}
												xLine += this.wordLength(doc, zz);
												zz = '';

												if (z[p] == '<') {
													doc.setTextColor(this.color);
												}
												if (z[p] == '>') {
													doc.setTextColor(0);
												}
											}
										}

										if (this.consolideerkaarten || this.cooperatieve_werkvormen) {
											doc.text(xLine, yLine, zz, null, null, 'center');
										} else {
											doc.text(xLine, yLine, zz);
										}
										yLine += LINE_HEIGHT;
									}

									// set font size for consolideer
									if (this.consolideerkaarten) {
										doc.setFontSize(14);
									}
								}
							}

							// dots picture
							let dotsWidth = cellWidth - 2 * DOTS_MARGIN;
							let dotsHeight = (cellWidth - 2 * DOTS_MARGIN) * DOTS_ASPECT_RATIO;
							doc.addImage(
								this.images[i].dots,
								'PNG',
								x + DOTS_MARGIN,
								y + cellHeight * DOTS_HEIGHT_PERC,
								dotsWidth,
								dotsHeight
							);

							// woordkaarten memory bingo
							if (this.typeNr == 0 || this.typeNr == 2 || this.typeNr == 3) {
								// font size
								let fontSize = this.nrCols == 2 ? 24 : 12;

								// compose word
								let word = this.images[i].woord;
								if (this.images[i].lidwoord != '') {
									word = this.images[i].lidwoord + ' ' + word;
								}

								// word position
								let xWord = x + cellWidth / 2;
								let yWord = y + cellHeight * WORD_HEIGHT_PERC;
								doc.setFontSize(fontSize);
								doc.text(xWord, yWord, word, null, null, 'center');
								const wordLength = doc.getTextDimensions(word).w / (72 / 25.6) + 5;
								// console.log('wordLength', word, wordLength.toFixed(1));

								// dot before 'het'
								let dotSize = fontSize / 7.5;
								if (this.images[i].lidwoord == 'het') {
									doc.setFillColor(255, 0, 0);
									doc.circle(
										xWord - wordLength / 2 - dotSize * 1.2,
										yWord - dotSize * 0.8,
										dotSize,
										'F'
									);
								}
							}

							// next cell in row
							x += cellWidth;

							// next image
							i++;
						}
					}
					y += cellHeight;
				}

				if (page < this.nrPages - 1) {
					doc.addPage();
				}
			}

			this.pdfString = doc.output('datauristring');
			this.pdfReady = true;
		},

		savePdf: function () {
			this.doc.save('pim.pdf');
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* copied from  https://github.com/cognitom/paper-css */

@page {
	margin: 0;
}

body {
	margin: 0;
}

/* card image */

.ci {
	height: 58.3%;
	display: block;
	margin: auto;
	margin-top: 11.6%;
}

/* card dots */

.cd {
	width: 94%;
	width: 94%;
}

/* card sentence */

.cs {
	margin-top: 11.6%;
	height: 58.3%;
	width: 100%;
}

/* card 2x3 */

.card2x3 {
	width: 85mm;
	height: 85mm;
}

/* card 3x4 */

.card3x4 {
	width: 56.67mm;
	height: 64mm;
}

/* card 3x4 with row margin */

.card3x4rm {
	width: 56.67mm;
	height: 59mm;
	margin-bottom: 5mm;
}
</style>
