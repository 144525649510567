<template>
	<div id="app">
		<top></top>
		<transition name="component-fade" mode="out-in">
			<router-view></router-view>
		</transition>
	</div>
</template>

<script>
// import 'babel-polyfill'

import logger from './js/log.js';
import top from '@/components/Top.vue';

export default {
	name: 'app',

	components: {
		top,
	},

	created() {
		// console.clear();
		//logger.getIp('www.pratenmetpim.nl');
	},
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');
@import '~bootstrap/dist/css/bootstrap.min.css';

#app {
	font-family: 'Quicksand', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	/*color: #2c3e50;*/
	/*margin-top: 60px;*/
}

.is-size-1 {
	font-size: 3rem;
}

.is-size-2 {
	font-size: 2.5rem;
}

.is-size-3 {
	font-size: 2rem;
}

.is-size-4 {
	font-size: 1.5rem;
}

.is-size-5 {
	font-size: 1.25rem;
}

.is-size-6 {
	font-size: 1rem;
}

.is-size-7 {
	font-size: 0.75rem;
}

.fill {
	background-size: 100% 100%;
}

.btn-red {
	background-image: url('./assets/btn-red.png');
}

.btn-blue {
	background-image: url('./assets/btn-blue.png');
}

.btn-green {
	background-image: url('./assets/btn-green.png');
}

.btn-purple {
	background-image: url('./assets/btn-purple.png');
	cursor: pointer;
}

.btn-orange {
	background-image: url('./assets/btn-orange.png');
	cursor: pointer;
}

.component-fade-enter-active,
.component-fade-leave-active {
	transition: opacity 0.3s ease;
}

.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active for <2.1.8 */ {
	opacity: 0;
}
</style>
