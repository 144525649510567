<template>
    <div>
        <div class="container my-5">
            <!-- prentenboeken -->
            <div class="row">
                <div class="col">
                    <p class="is-size-3">prentenboeken</p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-8 offset-md-2 text-left">
                    <p class="font-weight-bold">Gebruiksaanwijzing</p>
                    <p>
                        Er zijn 3 prentenboeken ontwikkeld voor Praten met Pim bij de thema’s ‘Dit ben ik’, ‘Er op uit’
                        en ‘Seizoenen’. Klik op het prentenboek als je het prentenboek digitaal wilt voorlezen. Je kunt
                        met de pijlen ‘bladeren’ door het boek.
                    </p>
                    <p>
                        Als je het prentenboek wilt printen, klik dan op de functie ‘bekijk/download PDF’ onder de
                        afbeelding van het boek. Het prentenboek wordt als PDF geopend. In de menubalk vind je de
                        functie om het PDF op te slaan of te printen. Klik daarna op de pijl naar links om terug te gaan
                        naar het overzicht met de prentenboeken.
                    </p>
                    <p>
                        Van ieder prentenboek is ook een digitale versie beschikbaar. Deze staat onder de download. Veel
                        plezier met de prentenboeken!
                    </p>
                </div>
            </div>

            <div class="row align-items-center mt-3">
                <div class="col-sm">
                    <img
                        class="img-fluid shadow pointer"
                        :src="url + paths[0] + '/' + paths[0] + '-1.jpg'"
                        @click="openGallery(0)"
                    />
                    <a :href="url + paths[0] + '/' + paths[0] + '_klein.pdf'">
                        <button type="button" class="btn btn-outline-dark w-100 mt-1 mb-3">
                            <i class="fa fa-lg fa-file-pdf-o"></i>&nbsp;bekijk/download PDF
                        </button>
                    </a>
                </div>
                <div class="col-sm">
                    <img
                        class="img-fluid shadow pointer"
                        :src="url + paths[1] + '/' + paths[1] + '-1.jpg'"
                        @click="openGallery(1)"
                    />
                    <a :href="url + paths[1] + '/' + paths[1] + '_klein.pdf'">
                        <button type="button" class="btn btn-outline-dark w-100 mt-1 mb-3">
                            <i class="fa fa-lg fa-file-pdf-o"></i>&nbsp;bekijk/download PDF
                        </button>
                    </a>
                </div>
                <div class="col-sm">
                    <img
                        class="img-fluid shadow pointer"
                        :src="url + paths[2] + '/' + paths[2] + '-1.jpg'"
                        @click="openGallery(2)"
                    />
                    <a :href="url + paths[2] + '/' + paths[2] + '.pdf'">
                        <button type="button" class="btn btn-outline-dark w-100 mt-1 mb-3">
                            <i class="fa fa-lg fa-file-pdf-o"></i>&nbsp;bekijk/download PDF
                        </button>
                    </a>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col">
                    <p class="is-size-3">geanimeerde prentenboeken</p>
                </div>
            </div>
            <div class="row my-0">
                <div class="col-sm">
                    <a href="https://www.youtube.com/watch?v=sQ3XfOhia0E">
                        <img class="img-fluid" src="../assets/v2.jpg" />
                    </a>
                </div>
                <div class="col-sm">
                    <a href="https://www.youtube.com/watch?v=58_EJCpmQ7M&t=4s">
                        <img class="img-fluid" src="../assets/v1.jpg" />
                    </a>
                </div>
                <div class="col-sm">
                    <a href="https://www.youtube.com/watch?v=sYuml3JgJBQ">
                        <img class="img-fluid" src="../assets/v3.jpg" />
                    </a>
                </div>
            </div>
        </div>
        <LightBox :media="images" ref="lightbox" :show-light-box="false" :nThumbs="5"></LightBox>
    </div>
</template>

<script>
import Vue from 'vue';
import VueLazyLoad from 'vue-lazyload';
Vue.use(VueLazyLoad);

import LightBox from 'vue-image-lightbox';
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');

export default {
    data: () => ({
        url: '/static/prentenboeken/',
        paths: ['Nieuwe kleren voor Pim', 'Onderweg met Sara en Pim', 'Spetteren met Mert'],
        images: [],
        imagecountseparator: 'van',
    }),

    components: {
        LightBox,
    },

    created() {
        console.clear();
    },

    methods: {
        openGallery(index) {
            let path = this.paths[index];
            this.images = new Array(15);
            for (let i = 0; i < this.images.length; i++) {
                var image = {
                    src: '',
                    thumb: '',
                };
                image.src = this.url + path + '/' + path + '-' + (i + 1) + '.jpg';
                image.thumb = this.url + path + '/' + path + '-' + (i + 1) + '.jpg';
                this.images[i] = image;
            }
            this.$refs.lightbox.showImage(0);
        },

        showGallery: function(path) {
            this.$router.push({
                name: 'Gallery',
                params: {
                    path: this.paths[path],
                },
            });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
    color: black;
}

.pointer {
    cursor: pointer;
}
</style>
