<template>
	<div>
		<br />
		<br />

		<!------------------------------------------------------------------------------------>
		<!-- foto and buttons -->
		<!------------------------------------------------------------------------------------>

		<div class="container-fluid foto mb-5">
			<div class="row">
				<div class="col-md-10 col offset-md-1 offset-0">
					<br />
					<h1 class="is-size-1 text-white font-weight-bold text-shadow">Spelen met woorden</h1>
					<br />
					<br />
					<div class="row">
						<div class="col btn-red fill font-weight-bold">
							<router-link to="/woorden">
								<p class="is-size-5 text-white my-3">woorden</p>
							</router-link>
						</div>
						<div class="col btn-blue fill font-weight-bold">
							<router-link to="/praatplaten">
								<p class="is-size-5 text-white my-3">praatplaten</p>
							</router-link>
						</div>
						<div class="col btn-green fill font-weight-bold">
							<router-link to="/muziek">
								<p class="is-size-5 text-white my-3">muziek</p>
							</router-link>
						</div>
						<div class="col btn-orange fill font-weight-bold">
							<router-link to="/werkvormen">
								<p class="is-size-5 text-white my-3">werkvormen</p>
							</router-link>
						</div>
						<div class="col btn-purple fill font-weight-bold">
							<router-link to="/meermateriaal">
								<p class="is-size-5 text-white my-3">meer materiaal</p>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!------------------------------------------------------------------------------------>
		<!-- home text -->
		<!------------------------------------------------------------------------------------>
		<section>
			<div class="container">
				<div class="row">
					<div class="col-md-8 offset-md-2 offset-sm-0 text-left">
						<p class="font-weight-bold">Welkom op de site ‘Praten met Pim'</p>
						<br />
						<p>
							Praten met Pim is een uniek project waarbij de doorgaande lijn NT2 (Nederlands als Tweede
							Taal) binnen de Enschedese Integrale Kindcentra een heel eigen gezicht heeft gekregen.
						</p>
						<p>
							Alle Kinderopvang- en onderbouwgroepen in Enschede maken deel uit van een Integraal
							KindCentrum (IKC). Hierbij is het belangrijk dat er voor kinderen een aantal herkenbare
							punten zijn die zij tegenkomen in de peutergroep en later op school. Samen met pedagogisch
							medewerkers, leerkrachten en coaches VVE is Praten met Pim ontwikkeld. Hierbij was de
							opdracht vanuit de gemeente Enschede om materialen te ontwikkelen voor peuters en kleuters
							gericht op woordenschat. Een goede basis voor ieder kind en een intensieve aanpak voor
							kinderen met een verminderde taalontwikkeling.
						</p>
						<p>Graag laten we ook u kennis maken met Pim en de materialen die zijn ontwikkeld.</p>
						<br />
						<p class="small">© Copyright {{ year }} Gemeente Enschede - versie {{ packageVersion }}</p>
						<p class="small">
							Het materiaal, de beelden/tekeningen en het concept is alleen vrij te gebruiken voor
							onderwijsdoeleinden. Heb je vragen over het materiaal, mail dan naar: pratenmetpim@gmail.com
						</p>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import top from './Top.vue';

export default {
	data: () => ({
		packageVersion: process.env.PACKAGE_VERSION || '0',
		year: new Date().getFullYear(),
	}),

	components: {
		top,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
	background: white;
}

.foto {
	background-image: url('../assets/foto_home.jpg');
	background-color: white;
	background-position: center center;
	padding: 3rem 1.5rem;
}

.fill {
	background-size: 100% 100%;
}

.btn-red {
	background-image: url('../assets/btn-red.png');
}

.btn-blue {
	background-image: url('../assets/btn-blue.png');
}

.btn-green {
	background-image: url('../assets/btn-green.png');
}

.btn-purple {
	background-image: url('../assets/btn-purple.png');
	cursor: pointer;
}

.small {
	font-size: 0.9rem;
}

.text-shadow {
	text-shadow: gray 0.01em 0.01em 1em;
}
</style>
