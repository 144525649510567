<template>
<div class="A4">
    <!-- pages -->
    <div class="sheet padding-20mm" v-for="(pageNr,index) in nrPages" :key="pageNr">
        <!-- cards per page -->
        <div class="row" v-for="(row,rind) in nrRows" :key="rind">
            <div class="card-border" v-bind:class="{'card2x3':twoColumns, 'card3x4':!twoColumns, 'card3x4rm':(row==marginAfterRow)}" v-for="(image,col,cind) in rows[index*nrRows+rind]" :key="cind">
                <!-- image -->
                <img class="ci" v-bind:class="{yellow: yellow}" v-bind:src="image.imageName" v-show="!((typeNr==1) && (col%2==1))" />

                <!-- sentence -->
                <div class="cs" v-bind:class="{yellow: yellow}" v-show="(typeNr==1) && (col%2==1)">
                    <div v-for="z in image.zin" :key="z">
                        <p class="is-size-4 sentence" v-html="z"> </p>
                    </div>
                </div>

                <!-- dots -->
                <img class="cd" v-bind:class="{yellow: yellow}" v-bind:src="dots" alt="stippels" />

                <!-- word -->
                <p v-show="(typeNr!=1)" v-bind:class="{'is-size-3':twoColumns, 'is-size-6':!twoColumns, yellow: yellow}">
                    <i v-bind:style="{color: dotColor}" class="fa fa-circle" v-show="image.lidwoord=='het'"></i> {{' ' + image.lidwoord + ' ' + image.woord}}
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: ['printSettings'],

    data: () => ({
        images: [],
        dots: '',
        dotColor: '',
        rows: [],
        nrPages: 1,
        nrImagesPerPage: 1,
        nrCols: 0,
        nrRows: 0,
        twoColumns: '',
        marginAfterRow: 2,
        typeNr: 0,
        yellow: false
    }),

    created() {
        // console.clear();
        // console.log('print.vue created');
        // console.log(this.printSettings);

        this.images = this.printSettings.images;
        this.dots = this.printSettings.dots;
        this.dotColor = this.printSettings.catColor;
        this.nrPages = this.printSettings.nrPages;
        this.nrImagesPerPage = this.printSettings.nrImagesPerPage;
        this.nrCols = this.printSettings.nrCols;
        this.nrRows = this.printSettings.nrRows;
        this.twoColumns = (this.nrCols == 2);
        this.marginAfterRow = this.printSettings.marginAfterRow;
        this.typeNr = this.printSettings.typeNr;

        // create array of rows depending on number of images per page
        var totalRows = Math.ceil(this.images.length / this.nrCols);
        // console.log('totalRows', totalRows);
        this.rows = new Array(totalRows);
        var p = 0;
        for (var i = 0; i < totalRows; i++) {
            this.rows[i] = [];
            for (var j = 0; j < this.nrCols; j++) {
                if (this.images[p]) {
                    this.rows[i].push(this.images[p]);
                    p++;
                }
            }
        }
        // console.log(this.rows);
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* copied from  https://github.com/cognitom/paper-css */

@page {
    margin: 0
}

body {
    margin: 0
}

.sheet {
    margin: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    page-break-after: always;
}


/** Paper sizes **/

body.A4 .sheet {
    width: 210mm;
    height: 297mm
}

body.A4.landscape .sheet {
    width: 297mm;
    height: 209mm
}


/** Padding area **/

.sheet.padding-10mm {
    padding: 10mm
}

.sheet.padding-15mm {
    padding: 15mm
}

.sheet.padding-20mm {
    padding: 20mm
}

.sheet.padding-25mm {
    padding: 25mm
}

/** For screen preview **/

@media screen {
    .sheet {
        background: white;
        box-shadow: 0 .5mm 2mm rgba(0, 0, 0, .3);
        /*margin: 5mm;*/
    }
}

/** Fix for Chrome issue #273306 **/

@media print {
    body.A3.landscape {
        width: 420mm
    }
    body.A3,
    body.A4.landscape {
        width: 297mm
    }
    body.A4,
    body.A5.landscape {
        width: 210mm
    }
    body.A5 {
        width: 148mm
    }
}

@page {
    size: A4
}

/* card image */

.ci {
    height: 58.3%;
    display: block;
    margin: auto;
    margin-top: 11.6%;
}

/* card dots */

.cd {
    width: 94%;
    width: 94%;
}

/* card sentence */

.cs {
    margin-top: 11.6%;
    height: 58.3%;
    width: 100%;
}

.yellow {
    border: 1px solid yellow;
}

/* .sentence {
    line-height: 1em;
} */

.row {
    margin: 0;
}

/* card border */

.card-border {
    border: 1px solid #ddd;
}

/* card 2x3 */

.card2x3 {
    width: 85mm;
    height: 85mm;
}

/* card 3x4 */

.card3x4 {
    width: 56.67mm;
    height: 64mm;
}

/* card 3x4 with row margin */

.card3x4rm {
    width: 56.67mm;
    height: 59mm;
    margin-bottom: 5mm
}
</style>
