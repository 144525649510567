<template>
    <div>
        <buttons></buttons>

        <div class="container">
            <div class="row font-weight-bold">
                <div class="col" v-for="(m, i) in materials" :key="i" :class="{ 'btn-purple fill': type == i }">
                    <p class="is-size-5 my-3" :class="{ 'text-white ': type == i }" @click="showPdf(m, i)">
                        {{ m }}
                    </p>
                </div>
            </div>
        </div>

        <transition name="component-fade" mode="out-in">
            <component :is="name"></component>
        </transition>
    </div>
</template>

<script>
import top from './Top.vue';
import buttons from './Buttons.vue';
import handleiding from './Handleiding.vue';
import haakpatroon from './Haakpatroon.vue';
import placemat from './Placemat.vue';
import kleurplaten from './Kleurplaten.vue';
import prentenboeken from './Prentenboeken.vue';
import dagritmekaarten from './Dagritmekaarten.vue';

export default {
    data: () => ({
        materials: ['handleiding', 'haakpatroon', 'placemat', 'kleurplaten', 'prentenboeken', 'dagritmekaarten'],
        name: '',
        type: 0
    }),

    components: {
        top,
        buttons,
        handleiding,
        haakpatroon,
        placemat,
        kleurplaten,
        prentenboeken,
        dagritmekaarten,
    },

    created() {
        console.clear();
        // console.log(localStorage.name);
        this.name = localStorage.name;
        this.type = this.materials.indexOf(this.name);
    },

    methods: {
        showPdf(name, type) {
            this.name = name;
            localStorage.name = name;
            this.type = type;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
