<template>
    <div>
        <buttons></buttons>
        <div class="container">
            <div class="row mt-5">
                <div class="col">
                    <p class="is-size-3">praatplaten</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 offset-md-2 text-left">
                    <p class="font-weight-bold">Gebruiksaanwijzing</p>
                    <p>
                        Er zijn 13 praatplaten gemaakt die passen bij de verschillende thema’s van Praten met Pim, zoals
                        ‘In de groep’ en Buitenspelen’. Illustraties van de woord- en vertelkaarten zijn verwerkt in de
                        praatplaten. Dit zorgt voor herkenning.
                    </p>
                    <p>
                        Klik op de praatplaat die je wilt downloaden of afdrukken. Deze wordt als PDF geopend. In de
                        menubalk vind je de functie om het PDF op te slaan of te printen. Klik daarna op de pijl naar
                        links om terug te gaan naar het overzicht met de praatplaten. Veel plezier met de praatplaten!
                    </p>
                </div>

                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Wonen.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Wonen.jpg" />
                    </a>
                </div>
                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Praatplaat_BoodschappenDoen_A4.pdf">
                        <img class="img-fluid" src="/static/praatplaten/BoodschappenDoen_WEB.jpg" />
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Buitenspelen_A4.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Buitenspelen_klein.jpg" />
                    </a>
                </div>
                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Familie_A4.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Familie_klein.jpg" />
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Klaslokaal_A4.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Klaslokaal_klein.jpg" />
                    </a>
                </div>
                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Praatplaat seizoenen.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Praatplaat seizoenen.jpg" />
                    </a>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Beroepen.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Beroepen.jpg" />
                    </a>
                </div>
                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Bij de dokter.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Bij de dokter.jpg" />
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Dierentuin.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Dierentuin.jpg" />
                    </a>
                </div>
                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Dit ben ik.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Dit ben ik.jpg" />
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Feesten.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Feesten.jpg" />
                    </a>
                </div>
                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Verkeer.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Verkeer.jpg" />
                    </a>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Eten en drinken.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Eten en drinken.jpg" />
                    </a>
                </div>
                <!-- <div class="col-sm-6 col-12 mt-3">
                    <a href="/static/praatplaten/Verkeer.pdf">
                        <img class="img-fluid" src="/static/praatplaten/Verkeer.jpg">
                    </a>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import top from './Top.vue';
import buttons from './Buttons.vue';

export default {
    components: {
        top,
        buttons,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
